body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

.chat-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  margin-bottom: 70px; /* Adjust this value to match the height of the input container */
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.dot {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.assistant {
  background-color: #28a745;
  color: white;
  align-self: flex-start;
}

.input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.input-container input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
}

.input-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
