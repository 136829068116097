.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-header {
  background-color: #333;
  color: white;
  text-align: center;
  left: 0;
  right: 0;
  padding: 20px;
}

.app-header h1 {
  margin: 0;
}

.app-body {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  padding: 10px;
  border-radius: 8px;
}

.user-message {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.assistant-message {
  background-color: #28a745;
  color: white;
  align-self: flex-start;
}

.message-input {
  display: flex;
  gap: 10px;
}

.message-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.message-input button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}
